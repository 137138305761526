<template>
  <div>
    <div
      class="hero-image mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="hero-text">
        <h1>Join us at Amchara Somerset</h1>
      </div>
    </div>
    <featured-in></featured-in>
    <div class="body-content mx-auto">
      <!-- A detox retreat Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto text-center">
            <div class="green-header-2-text">
              A detox retreat in the idyllic English countryside
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-9 mx-auto pb-lg-30 text-center">
            <p class>
              Our Somerset health retreat provides a personalised health
              experience for anyone who wishes to improve their physical health,
              emotional wellbeing and lifestyle in a positive, sustainable way.
            </p>
            <p class>
              Nestled within the picturesque English countryside, near the
              village of Langford Budville and set within beautiful private
              grounds, our 19th Manor House is the perfect location for you to
              relax, reboot and recharge. With beautiful interiors and
              comfortable bedrooms, you’ll find everything you need to unwind
              and enjoy your retreat experience.
            </p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-10 mx-auto text-center">
            <router-link
              to="/enquiries"
              class="btn d-inline-block btn-orange-gradient enquire-now-button"
              >ENQUIRE NOW</router-link
            >
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Amchara Someret Slider Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <div class="green-header-2-text">
              Take a look around Amchara Somerset
            </div>

            <!-- Image Slider Section -->
            <ul id="lightSlider_home_uk">
              <li
                v-for="file in ukImages"
                :data-thumb="'/images/location-slider/uk/' + file"
                :data-src="'/images/location-slider/uk/' + file"
              >
                <img
                  width="100%"
                  :src="'/images/location-slider/uk/' + file"
                  alt="Amchara Health Retreats Somerset, UK"
                />
              </li>
            </ul>

            <div class="mb-3">&nbsp;</div>
            <router-link
              to="/uk-gallery"
              class="btn btn-green-gradient green-link-button"
              >Full Gallery</router-link
            >
          </div>
        </div>
      </div>

      <div class="mt-2 mb-2">&nbsp;</div>
      <trip-advisor-somerset></trip-advisor-somerset>
      <hr width="80%" class="mt-5" />

      <!-- A detox retreat Section -->
      <div class="container-fluid mb-2">
        <div class="row">
          <div class="col-md-12 mx-auto pb-lg-30">
            <div class="green-header-2-text text-center">
              A personalised health retreat tailored to your needs
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 mx-auto text-center orange-text">
            <p>
              <i
                >Your Amchara retreat experience will be designed with your
                personal health needs and goals in mind. You will be immersed in
                a supportive and nurturing environment that enables you to
                switch off, relax and kickstart your health journey.</i
              >
            </p>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <featured-mags></featured-mags>
      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Whats included Section -->
      <div class="container-fluid mb-5">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">
              What’s included in a typical retreat
            </div>
            <div class="row">
              <div class="col-md-7">
                <div class="row mb-4 my-md-0">
                  <div class="col-md-12">
                    <ul class="home-bullet-points-left">
                      <li class="p-list-item mb-0 mb-md-3">
                        Advanced doctors screening
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        1:1 health screening on arrival
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        Personalised health experience
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        Tailored nutrition advice
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        4 x juices, smoothies, soups or broths
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        Detox supplements
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        Daily educational talks
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        Hosted countryside walks
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        Regular exercise classes (Yoga, Tai-Chi)
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        Continual nutritional & emotional support
                      </li>
                      <li
                        style="
                          list-style: none;
                          background: transparent
                            url('/images/logos/generic/tick-icon.jpg') no-repeat
                            top left;
                          padding: 0 0 0 28px;
                          margin-bottom: 0px;
                        "
                      >
                        Exit health screening
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-5 my-auto">
                <img
                  width="100%"
                  src="@/assets/images/pages/uk/whats-included-image.jpg"
                  alt="Whats included image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container my-3">
        <div class="row">
          <div class="col-md-10 mx-auto text-center">
            <router-link
              to="/enquiries"
              class="btn d-inline-block btn-orange-gradient enquire-now-button"
              >ENQUIRE NOW</router-link
            >
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Optional extras Section -->
      <div class="container-fluid mb-5">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">
              Optional extras to enhance your stay
            </div>
            <div class="row">
              <div class="col-md-5">
                <img
                  class="mx-auto d-block img-fluid"
                  src="@/assets/images/pages/uk/extras-image.jpg"
                  alt="Extras image"
                />
              </div>
              <div class="col-md-7 mx-auto my-4 my-md-0">
                <div class="row">
                  <div class="col-md-12">
                    <ul class="home-bullet-points-left">
                      <li class="p-list-item mb-0 mb-md-3">
                        Health and spa treatments - massage, reiki
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        Emotional support - 1:1 personal sessions
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        Colon cleansing – colonic hydrotherapy or/and enemas
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        Prescribed super supplement programme from a range of 6
                        super supplements including; wheatgrass, blue/green
                        algae, Aloe Ferox
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        Personalised health tests for hormones, genetics and gut
                        health
                      </li>
                      <li class="p-list-item mb-0 mb-md-3">
                        Tailored food programme derived from raw, vegan,
                        Palaeolithic and vegetarian Palaeolithic options all
                        using organic ingredients (whenever possible)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr width="80%" />
      <team></team>
      <hr width="80%" />

      <!-- Vimeo videos Section -->
      <div class="container-fluid mb-2">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">
              Hear from our clients
            </div>
          </div>
        </div>
        <video-testimonials></video-testimonials>
      </div>

      <div class="mt-2 mb-2">&nbsp;</div>
      <hr width="80%" />

      <!-- Rooms Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto pb-lg-30">
            <div class="green-header-2-text text-center">
              Rooms at Amchara Somerset
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="row">
              <div class="col-md-6 text-center">
                <div class="orange-text mb-3">Economy Rooms</div>
                <img
                  class="mb-3"
                  width="100%"
                  src="@/assets/images/pages/uk/economy-rooms.jpg"
                  alt="Economy rooms image"
                />
                <p>
                  The economy rooms are our smallest rooms and positioned on
                  second floor and unsuitable for those with mobility issues or
                  have problems with stairs. Options are a small en-suite
                  shower-room or bathroom.
                  <br />
                  <br />
                </p>
                <p style="color: #83c550">
                  Flat screen TV | DVD Player
                  <br />En-suite Bathroom / Shower Room
                </p>
              </div>
              <div class="col-md-6 text-center">
                <div class="orange-text mb-3">Standard Rooms</div>
                <img
                  class="mb-3"
                  width="100%"
                  src="@/assets/images/pages/uk/standard-rooms.jpg"
                  alt="Standard rooms image"
                />
                <p>
                  These rooms are larger than the economy rooms and they have
                  bigger bathrooms. They are mostly positioned on the first
                  floor with views to the back and side of the building with one
                  on the ground floor. These may be shared by 2 separate clients
                  (individual sharers).
                </p>
                <p style="color: #83c550">
                  Flat screen TV | DVD Player
                  <br />En-suite Bathroom
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-12 mx-auto">
            <div class="row">
              <div class="col-md-6 text-center">
                <div class="orange-text mb-3">Superior Rooms</div>
                <img
                  class="mb-3"
                  width="100%"
                  src="@/assets/images/pages/uk/superior-rooms.jpg"
                  alt="Superior rooms image"
                />
                <p>
                  There are 3 superior rooms. These are bigger rooms with one
                  having a 4 poster bed. Two are on the first floor with a third
                  on the second floor. The latter faces to the back and side of
                  the building. Superior Rooms also come with desks or dressing
                  tables.
                </p>
                <p style="color: #83c550">
                  Flat screen TV | DVD Player
                  <br />En-suite Bathroom <br />Armchair | Desk or Dressing
                  Table
                </p>
              </div>
              <div class="col-md-6 text-center">
                <div class="orange-text mb-3">Master Rooms</div>
                <img
                  class="mb-3"
                  width="100%"
                  src="@/assets/images/pages/uk/master-rooms.jpg"
                  alt="Master rooms image"
                />
                <p>
                  These rooms are located in the most advantageous positions in
                  the house. Large sash windows facilitate an abundance of
                  natural light. The position of 2 of the master rooms allows
                  one to indulge in the delightful panorama of the grounds and
                  the front of the house.
                </p>
                <p style="color: #83c550">
                  Flat screen TV | DVD Player
                  <br />En-suite Bathroom <br />Armchair &amp; Sofa | Desk or
                  Dressing Table
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <div class="row">
          <div class="col-md-10 mx-auto text-center">
            <router-link
              to="/enquiries"
              class="btn d-inline-block btn-orange-gradient enquire-now-button"
              >ENQUIRE NOW</router-link
            >
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Whats included Section -->
      <div class="container-fluid mb-5">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">
              The benefits of a stay with us...
            </div>
            <div class="row">
              <div class="col-md-7">
                <div class="row">
                  <div class="col-md-12">
                    <ul class="home-bullet-points-left">
                      <li class="p-list-item">
                        Personalised plan tailored to you
                      </li>
                      <li class="p-list-item">
                        The chance to relax, recharge & reboot
                      </li>
                      <li class="p-list-item">
                        Find your route back to optimum health
                      </li>
                      <li class="p-list-item">
                        Emotional support throughout your stay
                      </li>
                      <li class="p-list-item">
                        Before & after screening to see how far you’ve come
                      </li>
                      <li class="p-list-item">
                        The tools needed to keep on track at home
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-5 mt-5 mt-md-0">
                <img
                  class="mx-auto d-block img-fluid"
                  src="@/assets/images/pages/uk/benefits-image.jpg"
                  alt="Benefits image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Somerset prices section -->
      <!-- <div class="container-fluid mb-2">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">
              Amchara Somerset Prices
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="orange-text text-center mb-3">Standard Weeks*</div>
                <div class="table-responsive">
                  <table class="table info-table info-table-big">
                    <thead>
                      <tr>
                        <td>
                          Number
                          <br />of Days
                        </td>
                        <td>
                          <font class="font-weight-bold">Standard</font> Room
                          <br />
                          <font class="orange-text small"
                            >Private&nbsp;&nbsp;&nbsp;Shared (p.p.)</font
                          >
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>5</td>
                        <td>
                          <div class="d-inline me-4">£1350</div>
                          <div class="d-inline ms-4">£900</div>
                        </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>
                          <div class="d-inline me-4">£1450</div>
                          <div class="d-inline ms-4">£1000</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          8+
                          <br />
                          <span style="font-size: 13px">
                            <i>(price&nbsp;per&nbsp;day)</i>
                          </span>
                        </td>
                        <td>
                          <div class="d-inline ms-2 me-4">£200</div>
                          <div class="d-inline ms-4">£140</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-6 mt-4 mt-md-0">
                <div class="orange-text text-center mb-3">Premium Weeks**</div>
                <div class="table-responsive">
                  <table class="table info-table info-table-big">
                    <thead>
                      <tr>
                        <td>
                          Number
                          <br />of Days
                        </td>
                        <td>
                          <font class="font-weight-bold">Standard</font> Room
                          <br />
                          <font class="orange-text small"
                            >Private&nbsp;&nbsp;&nbsp;Shared (p.p.)</font
                          >
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>5</td>
                        <td>
                          <div class="d-inline me-4">£1450</div>
                          <div class="d-inline ms-4">£1000</div>
                        </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>
                          <div class="d-inline me-4">£1590</div>
                          <div class="d-inline ms-4">£1140</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          8+
                          <br />
                          <span style="font-size: 13px">
                            <i>(price&nbsp;per&nbsp;day)</i>
                          </span>
                        </td>
                        <td>
                          <div class="d-inline me-4">£220</div>
                          <div class="d-inline ms-4">£160</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="container mt-5">
        <div class="row">
          <div class="col-md-6 mx-auto">
            <div class="row">
              <div class="col-md-6 mx-auto text-center mb-4 mb-md-0">
                <router-link
                  to="/uk-pricing"
                  class="btn btn-green-gradient green-link-button"
                  >View Full Pricing</router-link
                >
              </div>
              <div class="col-md-6 mx-auto text-center">
                <router-link
                  to="/enquiries"
                  class="
                    btn
                    d-inline-block
                    btn-orange-gradient
                    enquire-now-button
                  "
                  >ENQUIRE NOW</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />
      <testimonials></testimonials>
      <div class="mt-3 mb-3">&nbsp;</div>
      <!--<hr width="80%" />
      <instagram></instagram>-->
    </div>

    <newsletter-cta></newsletter-cta>
  </div>
</template>

<script>
import FeaturedIn from "./page-partials/FeaturedIn";
import Team from "./page-partials/Team";
import FeaturedMags from "./page-partials/FeaturedMags";
import Locations from "./page-partials/Locations";
import TripAdvisorSomerset from "./page-partials/TripAdvisorSomerset";
import Testimonials from "./page-partials/Testimonials";
import Instagram from "./page-partials/Instagram";
import NewsletterCta from "./page-partials/NewsletterCta";
import VideoTestimonials from "./page-partials/VideoTestimonials";
export default {
  // child
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        i => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        i => i.slug == this.$route.path.replace("/", "")
      ).description
      // titleTemplate: null,
    };
  },
  data() {
    return {
      ukImages: [],
      bgImages: [require("@/assets/images/pages/uk/header.jpg")]
    };
  },

  methods: {
    fetchSliderImages() {
      window.axios.get("/location-slider-fetch-images").then(({ data }) => {
        this.ukImages = data.uk;
        this.redesignSlider("lightSlider_home_uk");
      });
    },
    redesignSlider(id) {
      var slider = $("#" + id).lightSlider({
        item: 1,
        autoWidth: false,
        slideMove: 1, // slidemove will be 1 if loop is true
        slideMargin: 4,

        addClass: "",
        mode: "slide",
        useCSS: true,
        cssEasing: "ease", //'cubic-bezier(0.25, 0, 0.25, 1)',//
        easing: "linear", //'for jquery animation',////

        speed: 800, //ms'
        auto: false,
        loop: false,
        pause: 4000,

        keyPress: false,
        controls: true,
        prevHtml:
          '<i class="fas fa-chevron-circle-left fa-2x" style="color: #8bc34a;"></i>',
        nextHtml:
          '<i class="fas fa-chevron-circle-right fa-2x" style="color: #8bc34a;"></i>',

        rtl: false,
        adaptiveHeight: false,

        vertical: false,
        verticalHeight: 380,
        vThumbWidth: 100,

        thumbItem: 6,
        pager: false,
        gallery: false,
        galleryMargin: 5,
        thumbMargin: 5,
        currentPagerPosition: "middle",

        enableTouch: true,
        enableDrag: true,
        freeMove: false,
        swipeThreshold: 40,

        responsive: [
          {
            breakpoint: 800,
            settings: {
              thumbItem: 5,
              controls: true,
              gallery: false
            }
          }
        ],

        onBeforeStart: function(el) {},
        onSliderLoad: function(el) {},
        onBeforeSlide: function(el) {},
        onAfterSlide: function(el) {},
        onBeforeNextSlide: function(el) {},
        onBeforePrevSlide: function(el) {}
      });

      slider.refresh();
      //slider.play();
    }
  },
  mounted() {
    // this.fetchSliderImages();
  },
  components: {
    FeaturedIn,
    Team,
    FeaturedMags,
    Locations,
    TripAdvisorSomerset,
    Testimonials,
    Instagram,
    NewsletterCta,
    VideoTestimonials
  }
};
</script>

<style>
</style>