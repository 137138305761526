<template>
  <div>
    <!-- Trip Advisor Section for Somerset -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <div class="green-header-2-text text-center">Read our reviews...</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 mx-auto mb-3">
          <div class="row mb-2">
            <div class="col-md-6 text-center">
              <div class="green-header-3-text text-center mb-3">
                Amchara Somerset, UK
              </div>
              <img
                src="@/assets/images/logos/generic/trip-advisor-logo-with-rating.svg"
                width="221"
                class="mb-4"
                alt="TA logo"
              />
            </div>
            <div class="col-md-6 text-center">
              <div class="green-header-3-text text-center mb-3">
                Amchara Gozo, Malta
              </div>
              <img
                src="@/assets/images/logos/generic/trip-advisor-logo-with-rating.svg"
                width="221"
                class="mb-4"
                alt="TA logo"
              />
            </div>
          </div>
        </div>
        <div class="col-md-10 mx-auto">
          <div class="row">
            <div class="col-md-4 text-center mb-5 mb-md-0">
              <div class="green-header-4-text text-center mb-3">
                Energised & Revitalised
              </div>
              <p>
                “I have benefited physically, emotionally and mentally in many
                ways. I have challenged myself, tried lots of new things and
                felt energised, revitalised and most importantly empowered to
                lead a happier, healthier life.”
              </p>
              <div class="green-header-5-text text-center">
                Pippa, June 2019
              </div>
              <div class="text-center">
                <img
                  src="@/assets/images/logos/generic/TA-5-circles.jpg"
                  width="91"
                  alt="Amchara Health Retreats on Trip Advisor"
                />
              </div>
            </div>
            <div class="col-md-4 text-center mb-5 mb-md-0">
              <div class="green-header-4-text text-center mb-3">Recommend</div>
              <p>
                “Firstly lovely staff and experts. An overriding feeling of
                support and happiness. Depending on what detox or diet you are
                on, all delicious and nutritious. Carefully worked out and
                individual. Loved all the yoga, meditation and talks.”
              </p>
              <div class="green-header-5-text text-center">
                Nic - January 2019
              </div>
              <div class="text-center">
                <img
                  src="@/assets/images/logos/generic/TA-5-circles.jpg"
                  width="91"
                  alt="Amchara Health Retreats on Trip Advisor"
                />
              </div>
            </div>
            <div class="col-md-4 text-center mb-5 mb-md-0">
              <div class="green-header-4-text text-center mb-3">Fantastic</div>
              <p>
                “Went to have a real break and get away from everything, it was
                fantastic - calm, healthy food (delicious) and wonderful walks
                and yoga. Very comfortable and I feel SO much better after only
                one week - thank you Amchara - really hope I will be able to
                come again.”
              </p>
              <div class="green-header-5-text text-center">
                GarboGubbins, January 2019
              </div>
              <div class="text-center">
                <img
                  src="@/assets/images/logos/generic/TA-5-circles.jpg"
                  width="91"
                  alt="Amchara Health Retreats on Trip Advisor"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-0 mt-md-5">
      <div class="row">
        <div class="col-md-10 mx-auto text-center">
          <router-link
            to="/enquiries"
            class="btn d-inline-block btn-orange-gradient enquire-now-button"
            >ENQUIRE NOW</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>